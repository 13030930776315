import $ from 'jquery';
import video from '../assets/index.mp4';
// (function($){
	
// 	$.fn.hover3d = function(options){
		
// 		var settings = $.extend({
// 			selector      : null,
// 			perspective   : 1000,
// 			sensitivity   : 20,
// 			invert        : false,
// 			shine         : false,
// 			hoverInClass  : "hover-in",
// 			hoverOutClass : "hover-out",
// 			hoverClass    : "hover-3d"
// 		}, options);
		
// 		return this.each(function(){
			
// 			var $this = $(this),
// 				$card = $this.find(settings.selector),
// 				currentX = 0,
// 				currentY = 0;


// 			if( settings.shine ){
// 				$card.append('<div class="shine"></div>');
// 			}
// 			var $shine = $(this).find(".shine");

// 			// Set perspective and transformStyle value
// 			// for element and 3d object
// 			$this.css({
// 				perspective: settings.perspective+"px",
// 				transformStyle: "preserve-3d"
// 			});
			
// 			$card.css({
// 				perspective: settings.perspective+"px",
// 				transformStyle: "preserve-3d",
// 			});

// 			$shine.css({
// 				position  : "absolute",
// 				top       : 0,
// 				left      : 0,
// 				bottom    : 0,
// 				right     : 0,
// 				transform : 'translateZ(1px)',
// 				"z-index" : 9
// 			});
			
// 			// Mouse Enter function, this will add hover-in
// 			// Class so when mouse over it will add transition
// 			// based on hover-in class
// 			function enter(event){
// 				$card.addClass(settings.hoverInClass+" "+settings.hoverClass);
// 				currentX = currentY = 0;
// 				setTimeout(function(){
// 					$card.removeClass(settings.hoverInClass);
// 				}, 1000);
// 			}
			
// 			// Mouse movement Parallax effect
// 			function move(event){
				
// 				var w      = $card.innerWidth(),
// 					h      = $card.innerHeight(),
// 					currentX = Math.round(event.pageX - $card.offset().left),
// 					currentY = Math.round(event.pageY - $card.offset().top),
// 					ax 	   = settings.invert ?  ( w / 2 - currentX)/settings.sensitivity : -( w / 2 - currentX)/settings.sensitivity,
// 					ay     = settings.invert ? -( h / 2 - currentY)/settings.sensitivity :  ( h / 2 - currentY)/settings.sensitivity,
// 					dx     = currentX - w / 2,
// 					dy     = currentY - h / 2,
// 					theta  = Math.atan2(dy, dx),
// 					angle  = theta * 180 / Math.PI - 90;

					
// 				if (angle < 0) {
// 					angle  = angle + 360;
// 				}
				

// 				$card.css({
// 					perspective    : settings.perspective+"px",
// 					transformStyle : "preserve-3d",
// 					transform      : "rotateY("+ax+"deg) rotateX("+ay+"deg)"
// 				});

// 				$shine.css('background', 'linear-gradient(' + angle + 'deg, rgba(255,255,255,' + event.offsetY / h * .5 + ') 0%,rgba(255,255,255,0) 80%)');
// 			}
			
// 			// Mouse leave function, will set the transform
// 			// property to 0, and add transition class
// 			// for exit animation
// 			function leave(){
// 				$card.addClass(settings.hoverOutClass+" "+settings.hoverClass);
// 				$card.css({
// 					perspective    : settings.perspective+"px",
// 					transformStyle : "preserve-3d",
// 					transform      : "rotateX(0) rotateY(0)"
// 				});
// 				setTimeout( function(){
// 					$card.removeClass(settings.hoverOutClass+" "+settings.hoverClass);
// 					currentX = currentY = 0;
// 				}, 1000 );
// 			}
			
// 			// Mouseenter event binding
// 			$this.on( "mouseenter", function(){
// 				return enter();
// 			});
			
// 			// Mousemove event binding
// 			$this.on( "mousemove", function(event){
// 				return move(event);
// 			});
			
// 			// Mouseleave event binding
// 			$this.on( "mouseleave", function(){
// 				return leave();
// 			});
			
// 		});
		
// 	};
	
// }($));
$(document).ready(() => {
  const $product_a = $('#product_a');
  const $header_nav = $('#header_nav');
  const $header_icon_btn = $('#header_icon_btn');
  const $mask_nav = $('#mask_nav');
  const $play_btn = $('#video_play_btn');
  const $video_mask = $('#video_mask');
  const $video_close = $ ('#video_close');

  if ($(window).scrollTop() < 862) {
    $header_nav.children('a').eq(0).addClass('active');
  } else if ($(window).scrollTop() >= 862) {
    $header_nav.children('a#product_a').addClass('active');
  }

  $play_btn.on('click', () => {
    if (!$video_mask.hasClass('video-show')) {
      $video_mask.addClass('video-show');
      $('#big_video').attr('src', video);
      $('#big_video').trigger('play');
      $('body').css('position','fixed');
    }
  })

  $video_close.on('click', () => {
    if ($video_mask.hasClass('video-show')) {
      $video_mask.removeClass('video-show');
      $('body').css('position','');
      $('#big_video').trigger('pause');
    }
  })

  $product_a.on('click', () => {
    if ($(window).scrollTop() >= 862) return false;
    $('html,body').animate({
      scrollTop: $('#product').offset().top
    }, 500);
  });

  $header_icon_btn.on('click', () => {
    if($mask_nav.hasClass('hide')) {
      $mask_nav.removeClass('hide');
      $header_icon_btn.addClass('hide-icon');
    } else {
      $mask_nav.addClass('hide');
      $header_icon_btn.removeClass('hide-icon');
    }
  });


  $(window).scroll(onScroll);
  function onScroll() {
    let screenTop = $(window).scrollTop();
    if (screenTop >= 862) {
      $header_nav.find('a').removeClass('active');
      $header_nav.children('a#product_a').addClass('active');
    } else {
      $header_nav.find('a').removeClass('active');
      $header_nav.children('a').eq(0).addClass('active');
    }
  }
})

